import {
  AppBar,
  Box,
  Container,
  Drawer,
  Toolbar,
  Typography,
  Unstable_Grid2,
  useMediaQuery,
} from "@mui/material";
import Link from "next/link";
import Image from "next/image";
import { useAppSelector } from "../../store/hooks";
import { selectIsAuthenticated, selectUserAvatar } from "../../store/selectors/authSelectors";
import AvatarMenu from "./AvatarMenu";
import HelpMenu from "./HelpMenu";
import { useTheme } from "@emotion/react";

export default function Topbar(props: {
  drawerOpen?: boolean;
  setDrawerOpen?: (open: boolean) => void;
}) {
  const { drawerOpen, setDrawerOpen } = props;
  const avatarPath = useAppSelector(selectUserAvatar); // TODO - add avatar to user {isAuthenticated && <ProfileDropDown avatar={avatar} />}
  const isAuthenticated = !!useAppSelector(selectIsAuthenticated);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <AppBar
      position="relative"
      component="div"
      sx={{
        background:
          "linear-gradient(0deg, rgba(249, 249, 249, 0.94), rgba(249, 249, 249, 0.94)), rgba(30, 30, 30, 0.75)",
        boxShadow: 0,
        backdropFilter: "blur(10px)",
        height: "4em",
        width: "100%",
        justifyContent: "center",
        padding: 0,
        margin: 0,
      }}
    >
      <Toolbar>
        {isAuthenticated ? (
          <Unstable_Grid2
            container
            alignItems={"center"}
            justifyContent={"flex-start"}
            sx={{ width: "100%" }}
          >
            {isMobile && (
              <Box
                sx={{
                  padding: 0.5,
                  borderRadius: theme.spacing(0.8),
                  minWidth: "20px",
                  backgroundColor: "black",
                  cursor: "pointer",
                  width: "40px",
                  height: "40px",
                }}
                onClick={() => setDrawerOpen!(!drawerOpen)}
              >
                <Image
                  alt="Submarine Logo"
                  src="/Submarine.svg"
                  width={0}
                  height={0}
                  style={{ height: "100%", width: "100%", position: "relative" }}
                />
              </Box>
            )}
            <Unstable_Grid2 container sx={{ marginLeft: "auto" }}>
              <HelpMenu />
              <AvatarMenu avatarPath={avatarPath} />
            </Unstable_Grid2>
          </Unstable_Grid2>
        ) : (
          <Link passHref href="/">
            <Box sx={{ cursor: "pointer" }}>
              <Image height={32} width={47} priority src="/submarine.png" alt="Submarine Me" />
            </Box>
          </Link>
        )}
      </Toolbar>
    </AppBar>
  );
}
