import {
  AppBar,
  Box,
  Divider,
  IconButton,
  Toolbar,
  Typography,
  Unstable_Grid2,
} from "@mui/material";
import Link from "next/link";
import Image from "next/image";
import NavLink from "./NavLink";
import Folder from "./Folder";
import Book from "./Book";
import { useRef, useState } from "react";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";

export default function Navigation({ isExpanded, setIsExpanded }) {
  const ref = useRef<HTMLDivElement>(null);

  return (
    <AppBar
      ref={ref}
      position="relative"
      component="nav"
      sx={{
        boxShadow: 0,
        borderRight: "#eaeaea",
        background: "#eaeaea",
        width: isExpanded ? "16rem" : "5.5rem",
        left: 0,
        transition: "width 0.5s",
        height: "100%",
        zIndex: 100,
        display: {
          xs: "none",
          sm: "block",
          md: "block",
        },
      }}
    >
      <Toolbar sx={{ height: "100%" }}>
        <Unstable_Grid2 container direction={"column"} sx={{ height: "100%" }}>
          <Link
            passHref
            href="/"
            style={{ textDecoration: "none", marginTop: "2rem", paddingBottom: "3rem" }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "50px 1fr",
              }}
            >
              <Image height={32} width={47} priority src="/submarine.png" alt="Submarine Me" />
              {
                <Typography
                  variant="h3"
                  sx={{
                    color: "black",
                    paddingLeft: "1rem",
                    display: "inline",
                    whiteSpace: "nowrap",
                    opacity: isExpanded ? 1 : 0,
                    transition: "opacity 0.5s",
                  }}
                >
                  Submarine
                </Typography>
              }
            </Box>
          </Link>
          <NavLink Icon={Folder} label="Content" href="/" isExpanded={isExpanded} />
          <NavLink
            Icon={Book}
            label="Documentation"
            href="https://docs.pinata.cloud/docs/what-is-submarine "
            isExpanded={isExpanded}
          />
          <Divider sx={{ marginTop: "auto", width: "100%" }} />
          <IconButton
            sx={{
              opacity: 0.5,
              width: "100%",
              borderRadius: "8px",
            }}
            onClick={() => setIsExpanded(!isExpanded)}
          >
            {isExpanded ? <KeyboardDoubleArrowLeftIcon /> : <KeyboardDoubleArrowRightIcon />}
          </IconButton>
        </Unstable_Grid2>
      </Toolbar>
    </AppBar>
  );
}
