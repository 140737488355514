import { Box, Drawer, Typography } from "@mui/material";
import Image from "next/image";

const MobileDrawer = ({ setDrawerOpen, drawerOpen }) => {
  return (
    <Drawer anchor={"left"} open={drawerOpen} onClose={() => setDrawerOpen(false)}>
      <Box sx={{ p: 2, width: 200, height: "100%", backgroundColor: "#C8C8C8" }}>
        <Typography variant={"h4"} sx={{ mb: 2 }}>
          Apps
        </Typography>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, mb: 2 }}>
          <Box
            onClick={() => setDrawerOpen(false)}
            sx={{
              padding: 0.5,
              borderRadius: (theme) => theme.spacing(0.8),
              minWidth: "20px",
              backgroundColor: "black",
              border: "2px solid",
              borderColor: "white",
              cursor: "pointer",
              width: "40px",
              height: "40px",
            }}
          >
            <Image
              alt="Submarine Logo"
              src="/Submarine.svg"
              width={0}
              height={0}
              style={{ height: "100%", width: "100%", position: "relative" }}
            />
          </Box>
          <Typography>Submarine</Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Box
            onClick={() => {
              window.location.replace(process.env.NEXT_PUBLIC_LOGIN_ROOT || "");
            }}
            sx={{
              border: "2px solid",
              padding: 0.5,
              borderRadius: (theme) => theme.spacing(0.8),
              minWidth: "20px",
              marginBottom: 1,
              backgroundColor: "black",
              cursor: "pointer",
              width: "40px",
              height: "40px",
            }}
          >
            <Image
              alt="Pinata Logo"
              src="/Pinnie.svg"
              width={0}
              height={0}
              style={{ height: "100%", width: "100%", position: "relative" }}
            />
          </Box>
          <Typography>Pinata</Typography>
        </Box>
      </Box>
    </Drawer>
  );
};

export default MobileDrawer;
