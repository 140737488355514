const Folder = () => (
  <svg width="20" height="17" viewBox="0 0 20 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1 3.08929V13.5357C1 14.6896 1.89543 15.625 3 15.625H17C18.1046 15.625 19 14.6896 19 13.5357V5.17857C19 4.02469 18.1046 3.08929 17 3.08929H11L9 1H3C1.89543 1 1 1.93541 1 3.08929Z"
      stroke="#0075FF"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Folder;
