import { Box, IconButton, Typography, Unstable_Grid2 } from "@mui/material";
import Link from "next/link";

const NavLink = ({ href, Icon, label, isExpanded }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <Link href={href} style={{ textDecoration: "none" }}>
        <IconButton sx={{ borderRadius: "8px", width: "100%", justifyContent: "left" }}>
          <Box sx={{ display: "grid", gridTemplateColumns: "30px 1fr", alignItems: "center" }}>
            <Icon style={{ marginRight: "100px" }} />
            <Typography
              variant="subtitle1"
              sx={{
                opacity: isExpanded ? 1 : 0,
                transition: "opacity 0.5s",
              }}
            >
              {label}
            </Typography>
          </Box>
        </IconButton>
      </Link>
    </Box>
  );
};

export default NavLink;
