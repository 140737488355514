import Footer from "./Footer";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { selectIsAuthenticated, selectUser } from "../../store/selectors/authSelectors";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { useIntercom } from "react-use-intercom";
import {
  Box,
  Container,
  Drawer,
  Link,
  Typography,
  Unstable_Grid2,
  useMediaQuery,
} from "@mui/material";
import { getKy } from "../../helpers/ky";
import { IntercomProvider } from "react-use-intercom";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import SharedHead from "./SharedHead";
import Topbar from "components/Topbar";
import { useTheme } from "@emotion/react";
import Navigation from "components/Navigation/SideNav";
import Image from "next/image";
import MobileDrawer from "./MobileDrawer";
import AppSwitcher from "./AppSwitcher";
import { refreshGatewayUrl, tryLogin } from "store/slices/authSlice";

const INTERCOM_APP_ID = process.env.NEXT_PUBLIC_INTERCOM_APP_ID || "f4cld255";
interface Props {
  children: React.ReactNode;
}

const PrivateLayout: React.FC<Props> = ({ children }: Props) => {
  const isAuthenticated = useAppSelector(selectIsAuthenticated);
  const ldClient = useLDClient();
  const router = useRouter();
  const user = useAppSelector(selectUser);
  const { boot, shutdown } = useIntercom();
  const theme = useTheme();
  const [userPlan, setUserPlan] = useState<any>(null);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const dispatch = useAppDispatch();
  // login process
  useEffect(() => {
    dispatch(tryLogin())
      .unwrap()
      .then((result) => {
        if (result.user) {
          dispatch(refreshGatewayUrl());
        }
      })
      .catch(() => {
        // user not logged in, can't find credentials in cookie storage.
        const bypassSSO = process.env.NEXT_PUBLIC_SELF_SSO === "true";
        if (bypassSSO) {
          console.log("bypassing SSO. Using Submarine.me login");
          router.push({ pathname: "/auth", query: { from: router.asPath } });
        } else {
          window.location.assign(
            `${process.env.NEXT_PUBLIC_LOGIN_ROOT}?from=${router.asPath}&submarineRedirect=true` ||
              "/auth"
          );
        }
      });
  }, []);

  // INTERCOM
  useEffect(() => {
    if (isAuthenticated) {
      boot({
        name: `${user.firstname} ${user.lastname}`,
        email: user.email,
      });
    } else {
      shutdown();
    }
  }, [isAuthenticated]);

  //LD
  useEffect(() => {
    if (user.email && userPlan) {
      const ldContext = {
        kind: "user",
        key: user.email,
        email: user.email,
        firstName: user.firstname || "",
        lastName: user.lastname || "",
        currentPlanType: userPlan.type,
        currentPlan: userPlan.nickname,
      };
      ldClient?.identify(ldContext, undefined, (res) => {
        console.log("LD init callback", res);
      });

      window.rudderanalytics?.identify(user.sub, {
        email: user.email,
        firstName: user.firstname,
        lastName: user.lastname,
      });
    }
    return () => {
      ldClient?.close();
    };
  }, [user, userPlan]);

  useEffect(() => {
    isAuthenticated && checkForPlan();
  }, [isAuthenticated]);

  const checkForPlan = async () => {
    if (router.pathname.includes("billing")) {
      return;
    }

    const userPlanInfo: any = await getUserBillingInfo();
    if (!userPlanInfo || !isValidPaidPlan(userPlanInfo)) {
      router.push("/billing");
    } else {
      setUserPlan(userPlanInfo.plan);
    }
  };

  const [isExpanded, setIsExpanded] = useState(false);
  const isTablet = useMediaQuery(theme.breakpoints.down("md"));
  useEffect(() => {
    setIsExpanded(!isTablet);
  }, [isTablet]);
  // TODO add loading spinner
  const getPaddingLeft = () => {
    if (isTablet) {
      return 0;
    }
    if (isExpanded) {
      return "16rem";
    }
    return "5.5rem";
  };
  const layout = isAuthenticated ? (
    <>
      <SharedHead />
      <Box
        sx={{
          position: "fixed",
          width: isExpanded ? "21rem" : "10.5rem",
        }}
      >
        <Box sx={{ display: "flex", height: "100vh" }}>
          {/*{displaySidebar && <AppSwitcher />}*/}
          {!isTablet && <Navigation isExpanded={isExpanded} setIsExpanded={setIsExpanded} />}
        </Box>
      </Box>
      <Box
        sx={{
          paddingLeft: getPaddingLeft(),
          width: "100%",
          transition: "margin-left 0.5s",
        }}
      >
        <Topbar drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
        <Container maxWidth={false}>
          <main>{children}</main>
          <Footer />
        </Container>
        <MobileDrawer drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />
      </Box>
    </>
  ) : null;
  return layout;
};

const withIntercom = (Component) =>
  function HOC(props) {
    return (
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <Component {...props} />
      </IntercomProvider>
    );
  };

export default withIntercom(PrivateLayout);

const getUserBillingInfo = async () => {
  try {
    const ky = getKy();
    const res = await ky(`${process.env.NEXT_PUBLIC_PINATA_API_URL}/billing/userStripeCustomer`);
    const userJson = await res.json();
    return userJson;
  } catch (error) {
    console.error(error);
    return null;
  }
};

const isValidPaidPlan = (userPlanInfo) => {
  if (
    userPlanInfo?.subscriptionItems[0]?.type === "PROFESSIONAL" ||
    userPlanInfo?.subscriptionItems[0]?.type === "EXTRA_MANAGED_GATEWAY"
  ) {
    return true;
  }
  if (userPlanInfo.plan.name === "Enterprise") {
    return true;
  }
  return userPlanInfo?.plan?.type !== 0 && userPlanInfo?.plan?.type !== 5; // both free plans disabled!
};
